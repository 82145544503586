var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table', {
    staticClass: "min-w-full"
  }, [_c('thead', [_c('tr', [_vm._l(_vm.headers, function (item, key) {
    return _c('th', {
      key: key,
      staticClass: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t(item)) + " ")]);
  }), _c('th', {
    staticClass: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  })], 2)]), _c('tbody', {
    staticClass: "bg-white"
  }, _vm._l(_vm.content, function (item, index) {
    return _c('tr', {
      key: index
    }, [_vm._l(_vm.headers, function (it, ind) {
      return _vm.content[index] ? _c('td', {
        key: ind,
        staticClass: "px-6 py-4 whitespace-no-wrap border-b border-gray-200"
      }, [it == 'type' ? _c('div', {
        staticClass: "text-xs leading-5 capitalize px-2 font-semibold rounded-full text-gray-900"
      }, [_vm._v(" " + _vm._s(_vm.content[index][it]) + " ")]) : it == 'status' && _vm.name == 'users' ? _c('div', [_c('p', [_c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.content[index][it],
          expression: "content[index][it]"
        }],
        staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
        attrs: {
          "id": "type",
          "name": "status",
          "autocomplete": "type"
        },
        on: {
          "change": [function ($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });
            _vm.$set(_vm.content[index], it, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }, function ($event) {
            return _vm.ChangeStatus(_vm.content[index]);
          }]
        }
      }, [_c('option', {
        attrs: {
          "selected": "",
          "disabled": ""
        },
        domProps: {
          "value": null
        }
      }, [_vm._v("Status")]), _vm._l(_vm.status, function (value, index) {
        return _c('option', {
          key: index,
          domProps: {
            "value": value
          }
        }, [_vm._v(" " + _vm._s(value) + " ")]);
      })], 2)])]) : it == 'countries' && _vm.name == 'users' ? _c('div', {
        staticClass: "flex"
      }, _vm._l(_vm.content[index][it], function (images, r) {
        return _c('span', {
          key: r
        }, [_c('img', {
          staticClass: "w-4 h-4 rounded-full mr-2",
          attrs: {
            "src": images
          }
        })]);
      }), 0) : it == 'isConverted' && _vm.name == 'subscribers' ? _c('div', [_vm.content[index] && _vm.content[index][it] ? _c('div', [_c('button', {
        staticClass: "rounded-full flex items-center text-green-500 focus:outline-none"
      }, [_c('i', {
        staticClass: "material-icons mr-2"
      }, [_vm._v("check_circle")]), _c('span', [_vm._v(_vm._s(_vm.$t("converted")))])])]) : _c('div', {
        staticClass: "flex"
      }, [_c('button', {
        staticClass: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
        staticStyle: {
          "color": "rgb(238, 43, 43)",
          "border-color": "rgb(238, 43, 43)"
        },
        on: {
          "click": function click($event) {
            return _vm.convertUser(_vm.content[index]);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.$t("convert")) + " ")])])]) : it == 'status' && _vm.name == 'orders' ? _c('div', [_c('p', [_c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.content[index][it],
          expression: "content[index][it]"
        }],
        staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
        attrs: {
          "id": "type",
          "name": "status",
          "autocomplete": "type"
        },
        on: {
          "change": [function ($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });
            _vm.$set(_vm.content[index], it, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }, function ($event) {
            return _vm.ChangeStatusOrder(_vm.content[index]);
          }]
        }
      }, [_c('option', {
        attrs: {
          "selected": "",
          "disabled": ""
        },
        domProps: {
          "value": null
        }
      }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _vm._l(_vm.statusOrder, function (value, index) {
        return _c('option', {
          key: index,
          domProps: {
            "value": value
          }
        }, [_vm._v(" " + _vm._s(value.name) + " ")]);
      })], 2)])]) : _c('div', {
        staticClass: "text-sm capitalize leading-5 text-gray-900"
      }, [it == 'role' ? _c('div', [_vm.content[index] && _vm.content[index][it] ? _c('span', [_vm._v(" " + _vm._s(_vm.content[index][it]["name"]))]) : _vm._e()]) : it == 'services' ? _c('div', [_vm.content[index]['oldObject'] && _vm.content[index]['oldObject'].settings && _vm.content[index]['oldObject'].settings.length > 0 ? _c('span', _vm._l(_vm.content[index]['oldObject'].settings, function (setting, i) {
        return _c('div', {
          key: i,
          staticClass: "flex flex-col lg:flex-row mb-2 items-center"
        }, [_c('img', {
          staticClass: "w-4 h-4 rounded-full mr-2",
          attrs: {
            "src": setting.flag
          }
        }), _c('div', {
          staticClass: "intro-y mr-1 flex-1 box-1 py-1",
          class: setting.confirmation ? 'active' : '',
          staticStyle: {
            "transform": "inherit"
          },
          attrs: {
            "id": "confirmation"
          }
        }, [_c('div', {
          staticClass: "text-smmm font-medium text-center"
        }, [_vm._v(" Confirmation ")])]), _c('div', {
          staticClass: "intro-y mr-1 flex-1 box-1 py-1",
          class: setting.fulfillement ? 'active' : '',
          staticStyle: {
            "transform": "inherit"
          },
          attrs: {
            "id": "Warehouse"
          }
        }, [_c('div', {
          staticClass: "text-smmm font-medium text-center"
        }, [_vm._v(" Warehouse ")])]), _c('div', {
          staticClass: "intro-y flex-1 box-1 py-1",
          class: setting.shipping ? 'active' : '',
          staticStyle: {
            "transform": "inherit"
          },
          attrs: {
            "id": "Shipping"
          }
        }, [_c('div', {
          staticClass: "text-smmm font-medium text-center"
        }, [_vm._v(" Shipping ")])])]);
      }), 0) : _vm._e()]) : _c('div', [it == 'date' && _vm.name == 'subscribers' ? _c('span', [_vm.content[index] && _vm.content[index].createdAt ? _c('span', [_vm._v(" " + _vm._s(_vm.$moment(_vm.content[index].createdAt).format("yyyy-MM-DD HH:mm")))]) : _vm._e()]) : _vm._e(), it == 'country' && _vm.name == 'subscribers' && _vm.content[index] && _vm.content[index][it] && _vm.content[index][it].length == 2 ? _c('span', [_vm._v(" " + _vm._s(_vm.$f.getCountryNameByCode(_vm.options, _vm.content[index][it])) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.content[index][it]) + " "), _vm.currentUser && _vm.currentUser.type == 'CEO' && _vm.name == 'users' && it == 'fullName' && _vm.CheckActivite(_vm.content[index]) ? _c('div', {
        staticClass: "w-full font-medium whitespace-nowrap"
      }, [_c('div', {
        staticClass: "online-indicator"
      }, [_c('span', {
        staticClass: "blink",
        class: _vm.CheckActivite(_vm.content[index]) == 'Online' ? 'online' : 'offline'
      })]), _c('h2', {
        staticClass: "online-text"
      }, [_vm._v(" " + _vm._s(_vm.CheckActivite(_vm.content[index])) + " ")])]) : _vm._e()])])])]) : _vm._e();
    }), _vm.name != 'productsOrder' ? _c('td', {
      staticClass: "px-6 py-4 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex justify-end"
    }, [_vm.getAccess(_vm.name, 'update') && _vm.name != 'statusHistories' && _vm.name != 'subscribers' && _vm.name != 'stockHistories' ? _c('router-link', {
      staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1",
      class: _vm.$colors.actionTable,
      attrs: {
        "to": "".concat(_vm.name, "/edit/").concat(_vm.content[index]['_id'])
      }
    }, [_c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v("edit")])]) : _vm._e(), _vm.getAccess(_vm.name, 'update') && _vm.name == 'subscribers' && _vm.content[index]['isConverted'] != true ? _c('router-link', {
      staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1",
      class: _vm.$colors.actionTable,
      attrs: {
        "to": "".concat(_vm.name, "/edit/").concat(_vm.content[index]['_id'])
      }
    }, [_c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v("edit")])]) : _vm._e(), _vm.getAccess(_vm.name, 'delete') ? _c('button', {
      staticClass: "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
      class: _vm.$colors.actionTable,
      on: {
        "click": function click($event) {
          return _vm.remove(_vm.content[index]['_id']);
        }
      }
    }, [_c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v("delete")])]) : _vm._e()], 1)]) : _vm._e()], 2);
  }), 0)]), _vm.content.length <= 0 ? _c('div', {
    staticClass: "bg-blue-100 py-8"
  }, [_c('span', {
    staticClass: "w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('i', {
    staticClass: "material-icons mx-3"
  }, [_vm._v("error")]), _c('span', {}, [_vm._v(_vm._s(_vm.$t("no_rows_selected")) + " ")])])])]) : _vm._e(), _c('vue-modal-2', {
    attrs: {
      "name": "ModalChangeSetSettings",
      "headerOptions": {
        title: 'Convert user'
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-full p-4 pt-0"
  }, [_c('div', {
    staticClass: "text-base mb-5 font-medium truncate"
  }, [_vm._v(" Seller: " + _vm._s(_vm.subscriber.user.fullName || "") + " ")]), _c('div', {
    staticClass: "change-status border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "settings"
    }
  }, [_vm._v("Country (warhousing)")])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.warehouses,
      "label": "name"
    },
    on: {
      "input": _vm.setSelectedContry
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.country)
          }
        }), _vm._v(" " + _vm._s(option.countryName) + " ")];
      }
    }]),
    model: {
      value: _vm.subscriber.user.country,
      callback: function callback($$v) {
        _vm.$set(_vm.subscriber.user, "country", $$v);
      },
      expression: "subscriber.user.country"
    }
  })], 1)]), _c('div', {
    staticClass: "flex mt-10 mb-5 items-center text-center"
  }, [_c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  }), _c('label', {
    staticClass: "block font-medium text-sm cursor-pointer text-green-500 w-full"
  }, [_c('span', {
    staticClass: "font-medium text-base text-green-800 dark:text-green-200"
  }, [_vm._v("Choisir les services")])]), _c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  })]), _c('div', {
    staticClass: "flex flex-col lg:flex-row"
  }, [_c('div', {
    staticClass: "border intro-y flex-1 box-1 py-10 cursor-pointer zoom-in",
    class: {
      active: _vm.subscriber.settings.confirmation
    },
    attrs: {
      "id": "confirmation"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('confirmation');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Confirmation ")])]), _c('div', {
    staticClass: "border intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in",
    class: {
      active: _vm.subscriber.settings.fulfillement
    },
    attrs: {
      "id": "fulfillement"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('fulfillement');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Warehouse ")])]), _c('div', {
    staticClass: "border intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in",
    class: {
      active: _vm.subscriber.settings.shipping
    },
    attrs: {
      "id": "shipping"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('shipping');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Shipping ")])])])])]), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v("Cancel")]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.confirmChange
    }
  }, [_vm._v("Confirme")])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }